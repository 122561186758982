.DropdnMenuItem {
  display: flex;
  opacity: 1;
  font-family: roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: rgba(17, 17, 17, 0.84);
  text-wrap: wrap;
  align-items: flex-start;
  border: 0;
  margin-left: 5px;
  width: 98%;
}

.drop-down {
  color: var(--dark-default-typography-text-black-light);
}
.drop-down-header {
  margin-left: 10px;
  margin-bottom: 5px;
  height: 60px;
  font-family: roboto;
  font-size: 19px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: black;
}
.-menu-item-text {
  width: 224px;
  height: auto;
  margin-left: 2px;
  font-family: roboto;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: rgba(17, 17, 17, 0.84);
  border: 0;
  background-color: transparent;
  padding: 0;
}
.view_all_link {
  width: 404px;
  align-items: center;
  justify-content: center;
}

.drop-down-name {
  height: 28px;
  margin: 0 0 0 0px;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: rgba(17, 17, 17, 0.84);
  border: 0;
  background-color: transparent;
  padding: 0;
}

.drop-down-latest-document {
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.2px;
  color: #757575;
}
.label {
  width: 150px;
  height: 19px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 0.2px;
  text-align: right;
  color: rgba(17, 17, 17, 0.64);
}
