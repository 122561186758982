.collapse.show {
  visibility: visible;
}

.nav-tabs .nav-link.active {
  background-color: #f2f2f2;
}

.grid-table {
  border: 0;
  background-color: #f2f2f2;
  padding-top: 40px;
}

.active.nav-link .tab-header-main {
  color: #111111;
}

.graph-nav {
  background-color: white;
  .active {
    border-bottom: 1px solid #ededed;
  }
}

.scenario-header,
.summary-graph {
  background-color: white;
  border-radius: 0;
  border: 0;
}

.compliance-dashboard {
  .card-body {
    padding: 0px;
  }
}

.credit {
  color: #5a9400;
}

.deficit {
  color: #fb7300;
}

.compliance-nav-item {
  min-width: 200px;
  label {
    cursor: pointer;
  }
  .tab-header-sub-text {
    text-align: center;
  }
  .active .tab-header-sub-text {
    color: #111111;
  }

  .tab-header {
    font-size: 12px;
    color: rgba(17, 17, 17, 0.5);
  }
}

.expandable-table {
  margin-bottom: 0px;
  border-bottom: 1px #e0e0e1 solid;
  thead > tr > th {
    background-color: #fafafa;
    border-bottom-color: #e0e0e1;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .align-top,
  #operation {
    width: 40px;
    max-width: 40px;
    min-width: 40px;
    padding-left: 0;
    padding-right: 0;
  }
  tbody > tr > td {
    border-bottom-color: #e0e0e1;
    line-height: 40px;
  }
  #expansion {
    width: 40px;
    padding-top: 20px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  #padding {
    min-width: 63.5px;
    width: 63.5px;
  }
}

.scenario-header,
.graph-header {
  line-height: 52px;
}

.graph-header {
  border-bottom: solid 1px #666;
  font-size: 15px;
  color: #111111;
}

.scenario-name-title {
  font-size: 15px;
  color: rgba(17, 17, 17, 0.64);
  font-weight: bold;
}

.compliance-filter-content {
  .flatpickr-calendar.static {
    position: relative;
  }
}
.scenario-header {
  cursor: pointer;
  border-bottom: solid 1px #666666;
}
