.compliance-dropdown-filter .dropdown-item {
  width: 212px;
}

.compliance-scheme-dropdown-filter .dropdown-item {
  width: 440px;
}

#compliance-filter {
  .form-label {
    margin-bottom: 0;
  }

  .bp-formfield label {
    @apply capitalize font-light;
    color: #111111;
  }

  .bp-filter .padded {
    padding: unset;
    padding-left: 15px;
    padding-right: 15px;
  }

  button {
    height: 2.5rem;
    padding-left: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .apply-button {
    line-height: 0;
  }

  .btn-icon-suffix {
    margin-right: 2px;
  }

  select,
  input {
    background-color: white;
    cursor: pointer;
  }

  .bp-filter {
    box-shadow: none;
    background-color: #fafafa;
  }

  .bp-green-gradient {
    display: none;
  }

  .bp-formfield {
    padding-bottom: 30px;
    margin-bottom: 16px;
    flex-basis: auto;
    flex-grow: 0;
    min-width: 212px;
    .flatpickr-wrapper {
      ::placeholder {
        color: black;
        opacity: 1;
        overflow: visible;
      }
    }
    overflow: visible;
  }

  .compliance-scheme-filter {
    min-width: 440px;
  }

  .accordion-button,
  .accordion-body {
    border: 0;
    border-radius: 0;
    background-color: #fafafa;
  }

  .accordion-body {
    border-top: 1px solid #dedede;
    overflow: visible;
  }

  .accordion-header {
    @apply px-7 py-3 pl-1;
    background-color: #fafafa;
  }
  .accordion-item {
    overflow: visible;
  }

  .accordion {
    overflow: visible;
  }

  .compliance-filter-content {
    .flatpickr-calendar.static {
      position: relative !important;
    }
  }

  .invalid-feedback {
    position: absolute;
    margin-top: 4.5rem;
  }
}
