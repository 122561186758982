// BOL Modal css
$backgroundColor: #fafafa;
$fontColor: #111111;
$buttonBorderColor: #cccccc;
$shadowAndBorderColor: #ededed;

#bol-modal-header {
  border-bottom: 1px solid $shadowAndBorderColor;
  background-color: $backgroundColor;
  padding-left: 30px;
}

#bol-modal-body {
  background-color: $backgroundColor;
  padding-left: 41px;
  padding-top: 52px;
}

.bol-modal-table {
  margin-top: 40px;
  margin-bottom: 40px;
  max-height: 750px;
  overflow-y: auto;

  .dropdown {
    cursor: pointer;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    border-top: 0 solid black;
  }

  thead {
    // This is necessary for sticky header to always be on top
    // so that scrolling content isn't visible in the background.
    position: relative;
    z-index: 1;
    tr th {
      top: 0px;
      position: sticky;
      background-color: $backgroundColor;
      border-bottom: solid 1px $buttonBorderColor;
      border-top: 0 solid black;
    }
  }

  tr td {
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: white;
  }

  th,
  td {
    vertical-align: middle;
  }

  th:first-child {
    padding-left: 30px;
  }

  .standard-row td:first-child {
    padding-left: 30px;
  }

  .standard-row td:nth-last-child(2),
  th:nth-last-child(2) {
    text-align: right;
  }

  .standard-row td:last-child {
    text-align: center;
  }

  .summary-row {
    td {
      border-bottom: solid 1px $shadowAndBorderColor;
      text-align: right;
    }
    height: 64px;
    position: sticky;
    bottom: 0px;
    div {
      align-items: center;
    }
    .first-text {
      font-size: 13px;
      color: #838383;
    }

    .summary-volume {
      font-size: 18px;
      color: $fontColor;
    }
  }

  .summary-row.first {
    position: sticky;
    bottom: 64px;
    box-shadow: -0px -6px 20px 0px $shadowAndBorderColor;
    -moz-box-shadow: -0px -6px 20px 0px $shadowAndBorderColor;
    -webkit-box-shadow: -0px -6px 20px 0px $shadowAndBorderColor;
  }
}

.bol-modal-buttons {
  button {
    border: 1px solid $buttonBorderColor;
  }
}

.bol-modal-top-text {
  font-size: 15px;
  color: #757575;
}

.bol-modal-top-value {
  font-size: 15px;
  color: $fontColor;
}

// Split BOL Modal css
#split-bol-modal-info {
  padding-top: 30px;
  padding-bottom: 30px;
}

#split-bol-modal-buttons {
  button {
    border: 1px solid $buttonBorderColor;
  }
}

#split-bol-modal-body {
  background-color: $backgroundColor;
  border-bottom: 1px solid $shadowAndBorderColor;
  padding-left: 39px;
  padding-right: 42px;
  padding-bottom: 30px;
  padding-top: 30px;

  .part-text {
    font-size: 13px;
    color: $fontColor;
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .part-border {
    border-bottom: solid 1px $buttonBorderColor;
    margin-bottom: 15px;
  }

  .h3 {
    margin-bottom: 0px;
  }
}
