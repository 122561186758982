.copro-us-shipments-load-more-container {
  display: flex;
}

.copro-us-shipments-load-more-button:hover {
  color: #000000 !important;
}

#copro-collapsible-card-title {
  font-size: 18px;
}

.shipment-cards {
  overflow: auto;
  max-height: 75vh;
}
