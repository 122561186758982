.bp-header {
  @apply relative z-20 flex items-center bg-white shadow-md sm:items-center sm:flex-row gap-x-3;

  &::after {
    content: "";
    @apply right-0 bottom-0 block left-0 items-center w-full bg-gray-300 h-px absolute rounded-lg;
  }

  .bp-navbar-icon {
    @apply relative flex flex-row p-7;

    .img {
      @apply w-10 h-10;
    }

    &::after {
      content: "";
      @apply right-0 top-0 block items-center h-full bg-gray-300 w-px absolute rounded-lg;
    }
  }

  .bp-header-container {
    @apply flex justify-between w-full pr-8 gap-x-3;
    .bp-logo {
      @apply w-40 h-10 mx-auto;
    }

    .bp-avatar-item {
      @apply flex items-center h-full gap-3;

      .avatar-dropdown {
        @apply left-0;
      }

      .bp-login-button {
        @apply px-3 py-2 font-medium text-white rounded bg-bp-green-800 hover:opacity-50;
      }
    }
  }
}

.ant-dropdown {
  .bp-avatar-container {
    @apply w-full p-3;
    li {
      @apply w-full;
    }
  }
  .avatar-content {
    @apply flex flex-row items-center justify-between gap-x-3;
    div {
      @apply flex flex-col;

      .bp-account-name {
        @apply w-full font-bold break-words;
      }
    }
  }
}
